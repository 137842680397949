import { adminClient } from "@api/admin/AdminClient";
import { CommentsCard } from "@components/admin/comments/Comment";
import ImageModalComponent from "@components/admin/items/details/ImageModalComponent";
import ItemProfileDropDownActionsComponent from "@components/admin/items/details/ItemProfileDropDownActionsComponent";
import PageTitle from "@components/admin/PageTitle";
import QrCode from "@components/admin/QrCode";
import InternalLink from "@components/InternalLink";
import WebpImage from "@components/WebpImage";
import { queryApi } from "@hooks/api";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Skeleton from "@material-ui/lab/Skeleton";
import { AdminItemResponse } from "@reshopper/admin-client";
import { toDanishPriceFormat } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";


const useStyles = makeStyles(() =>
	createStyles({
		TableCell: {
			borderBottom: "none",
			paddingTop: "4px",
			paddingBottom: "4px",
		},
		TopCell: {
			paddingTop: "0",
			borderBottom: "none",
		},
		SmallIcon: {
			verticalAlign: "middle",
			paddingBottom: "4px",
		},
		TypographyFontSize: {
			fontSize: "0.875rem",
		},
		button: {
			height: 20,
			marginLeft: 10
		},
	}
	),
);

interface TableForItemDetailsPageProps {
	item: AdminItemResponse;
}

function TableForItemDetailsPage(props: TableForItemDetailsPageProps): JSX.Element {
	const classes = useStyles();

	function renderTableDescriptionForItemDetailsPageRow(description: string): JSX.Element {
		return <TableCell className={classes.TableCell} key={description}>
			<Box fontWeight="fontWeightBold">
				{description}
			</Box>
		</TableCell>
	}

	function renderSoldTo(): JSX.Element {
		if (props.item.outOfStockByTransactionId) {
			return <InternalLink href={"/admin/transactions/details/?id=" + encodeURIComponent(props.item.outOfStockByTransactionId)}>
				Transaction
			</InternalLink>
		}
		if (props.item.soldOutToUserId) {
			return <InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(props.item.soldOutToUserId)}>
				User
			</InternalLink>
		}
		return <Typography>-</Typography>
	}

	function generateData() {
		let data = "";
		data += props.item.segment || "[Segment missing]";
		data += "/";
		data += props.item.category || "[Category missing]";
		data += ", ";
		data += props.item.gender || "[Gender missing]";
		data += ", ";
		data += props.item.brandOrTitle || "[Brand or Title missing]";
		data += ", ";
		data += props.item.size || "No size";
		data += ", ";
		data += props.item.condition || "[Condition missing]";
		data += ".";

		return data;
	}

	const correctlyIndexedIn = props.item.shouldBeIndexedIn && props.item.isIndexedIn.find(x => x == props.item.shouldBeIndexedIn);
	const incorrectlyIndexedIn = props.item.isIndexedIn.filter(x => x !== correctlyIndexedIn);

	return <>
		<Table size="small" style={{ width: "auto" }}>
			<TableBody>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Extended Description")}
					<TableCell className={classes.TableCell}>
						{props.item.extendedDescription ?? "Extended Description"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Data")}
					<TableCell className={classes.TableCell}>
						{generateData()}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Created")}
					<TableCell className={classes.TableCell}>
						{props.item.createdUtc !== null ?
							toDanishDateFormat(props.item.createdUtc) :
							"No Created Set"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Sold Date")}
					<TableCell className={classes.TableCell}>
						{props.item.soldOutDateUtc ?
							toDanishDateFormat(props.item.soldOutDateUtc) :
							"-"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Sold to")}
					<TableCell className={classes.TableCell}>
						{renderSoldTo()}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Price")}
					<TableCell className={classes.TableCell}>
						{toDanishPriceFormat(props.item.priceInHundreds / 100) ?? "No Price"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Quantity")}
					<TableCell className={classes.TableCell}>
						{props.item.quantityInStock ?? "No quantity"}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Views/Reports")}
					<TableCell className={classes.TableCell}>
						{props.item.viewsCount ?? 0}
						/
						{props.item.reports?.length ?? 0} {props.item.ignoreFutureReportsFromTimestampUtc ? "Approved, future reports are ignored" : ""}
					</TableCell>
				</TableRow>
				<TableRow>
					{renderTableDescriptionForItemDetailsPageRow("Indexed")}
					<TableCell className={classes.TableCell}>
						<Box style={{ display: "flex", gap: 2 }}>
							{correctlyIndexedIn && <CheckCircleOutlineIcon color="primary" />}
							{!correctlyIndexedIn && incorrectlyIndexedIn.length === 0 && <NotInterestedIcon color="error" />}
							{incorrectlyIndexedIn.length > 0 && <HelpOutlineIcon color="inherit" />}

							{correctlyIndexedIn ?
								<Typography color="primary">Correctly indexed in: {correctlyIndexedIn}</Typography> :
								props.item.shouldBeIndexedIn && <Typography color="secondary">Should be indexed in: {props.item.shouldBeIndexedIn}</Typography>
							}
							{incorrectlyIndexedIn.length > 0 && <>
								<Typography color="error">Incorrectly indexed in: </Typography>{incorrectlyIndexedIn.map(x => <Typography color="error">- {x}</Typography>)}
							</>}
						</Box>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	</>
}

function InsertSkeleton(): JSX.Element {

	function tableSkeleton(): JSX.Element[] {
		let elements = []
		for (let index = 0; index < 8; index++) {
			elements.push(
				<TableRow key={"skeleton" + index}>
					<Skeleton component="td" height={28} width={547} />
				</TableRow>
			)
		}
		return elements;
	}

	return <>
		<Skeleton height={48} />
		<Divider />
		<Box display="flex" flexDirection="row">
			<Box flexGrow={1}>
				<Skeleton variant="rect" height={200} width={200} />
			</Box>
			<Table size="small">
				<TableBody>
					{tableSkeleton()}
				</TableBody>
			</Table>
		</Box>
	</>
}

export default function ItemDetailsComponent(props: {
	item: AdminItemResponse | undefined;
}) {
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);
	const [imageUrl, setImageUrl] = useState("");

	const [largeImages] = queryApi(
		async (options) => {
			if (!props.item?.id)
				return undefined;

			return await adminClient().adminItemsLargeImagesGet(props.item.id!, options);
		},
		[props.item, props.item?.id]);

	const [transaction] = queryApi(
		async (options) => {
			if (!props.item?.outOfStockByTransactionId)
				return undefined;

			return await adminClient().adminTransactionsDetailsJsonGet(props.item.outOfStockByTransactionId!, options);
		},
		[props.item, props.item?.outOfStockByTransactionId]);

	function handleImageOpen(imageUrl: string | undefined) {
		if (!imageUrl) {
			return;
		}

		setImageUrl(imageUrl);
		setIsImageModalOpen(true);
	}

	function renderThumbnailImages() {
		if (!largeImages) {
			return null;
		}

		let images = [];
		for (let i = 1; i < largeImages.length; i++) {
			if (!largeImages[i]?.url) {
				return;
			}
			images.push(
				<Box key={i}>
					<WebpImage
						key={"avatar-" + i}
						src={largeImages[i].url!}
						size={100}
						onClick={() => handleImageOpen(largeImages[i].url)}
					/>
				</Box>
			)
		}
		return <Box>
			<Divider style={{ marginTop: 10, marginBottom: 20 }} />
			<Box style={{ display: "flex" }}>
				{images}
			</Box>
		</Box>
	}

	if (props.item?.id === undefined || largeImages === undefined) {
		return <InsertSkeleton />
	}

	return <>
		<PageTitle title={props.item.description}>
			<ItemProfileDropDownActionsComponent item={props.item} />
		</PageTitle>
		<Card>
			<CardContent>
				<Box style={{
					display: "flex",
					justifyContent: "space-between"
				}}>
					<Box display="flex">
						{largeImages && <Box minWidth={250}>
							<WebpImage
								images={largeImages}
								size={250}
								onClick={() => handleImageOpen(largeImages[0].url)}
							/>
						</Box>}
						<TableForItemDetailsPage item={props.item} />
					</Box>
					<QrCode
						text={`reshopper%3A%2F%2Fi%2F${props.item.id}`}
						link={`https://www.reshopper.${props.item.country}/i/${props.item.id}`}
						size={155}
					/>
				</Box>
				{renderThumbnailImages()}
				<ImageModalComponent
					isModalOpen={isImageModalOpen}
					onClose={() => setIsImageModalOpen(false)}
					imageUrl={imageUrl}
				/>
			</CardContent>
		</Card>

		<CommentsCard
			objectId={props.item.id}
			style={{ marginTop: 16 }}
		/>
	</>
}
