import CodeTextArea from "@components/admin/CodeTextArea";
import { Dialog, DialogContent } from "@material-ui/core";

export default function ItemJsonModalComponent(props: {
  isModalOpen: boolean;
  onClose: () => void;
  itemJson: string;
}) {
  function generateItemJson() {
    if (!props.itemJson)
      return;

    let prettyJson = props.itemJson;
    return <CodeTextArea
      code={prettyJson}
      readonly
    />
  }

  return <Dialog
    fullWidth
    maxWidth="xl"
    open={props.isModalOpen}
    onClose={() => props.onClose()}
  >
    <DialogContent>
      {generateItemJson()}
    </DialogContent>
  </Dialog>

}