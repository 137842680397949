import { Box, Button, Typography } from "@material-ui/core";
import { navigate } from "gatsby";

export default function NotFoundComponent(props: { 
    message: string,
    backUrl: string
  }) {
    return <>
    <Box>
        <Typography
            variant="h4">
            {props.message}
            </Typography>
        <Button
            onClick={() => navigate(props.backUrl)}
            variant="contained"
            color="primary">
            Back
        </Button>
    </Box>
</>;
  }