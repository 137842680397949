import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import FindReplaceIcon from "@material-ui/icons/FindReplace";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function UpdateIndexMenuItemComponent(props: { id: string, onClose: () => void }): JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleOpen() {
        setDialogOpen(true);
        props.onClose();
    }

    return <>
        <MenuItem button
            onClick={handleOpen}>
            <ListItemIcon>
                <FindReplaceIcon />
            </ListItemIcon>
                  Update Index
              </MenuItem>
              
        <ConfirmationDialogComponent
            isDialogOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onConfirm={() => performAction(
                () => adminClient().adminReindexItemsPost({body: {itemIds: [props.id]}}), 
                "Failed to update index")}
            title="Confirm update index"
            description="Are you sure you want update index"
        />
    </>
}
