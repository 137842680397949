import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function MarkAsSoldMenuItemComponent(props: { id: string, onClose: () => void }): JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleOpen() {
        setDialogOpen(true);
        props.onClose();
    }

    return <>
        <MenuItem button
            onClick={handleOpen}
        >
            <ListItemIcon>
                <LocalAtmIcon />
            </ListItemIcon>
                  Mark as Sold
              </MenuItem>
        <ConfirmationDialogComponent
            isDialogOpen={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onConfirm={() => performAction(
                () => adminClient().adminItemsMarkItemAsSoldPost({id: props.id}), 
                "Failed to mark item as sold")}
            title="Confirm mark as sold"
            description="Are you sure you want to mark this item as sold?"
        />
    </>
}
