import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function ApproveItemMenuItemComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <AssignmentTurnedInIcon />
      </ListItemIcon>
                  Approve Item
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminItemsClearReportsPost({id: props.id}), 
        "Failed to clear reports and approve item")}
      title="Confirm approve item"
      description="Are you sure you want to remove reports and approve item?"
    />
  </>
}