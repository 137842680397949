import { ListItemIcon, MenuItem } from "@material-ui/core";
import StorefrontIcon from "@material-ui/icons/Storefront";
import React from "react";

const GoToShopMenuItemComponent = React.forwardRef((props: { userId: string }, ref: any) => {

    return <MenuItem button onClick={() => window.location.href = "/admin/users/details/?id=" + encodeURIComponent(props.userId)} ref={ref}>
        <ListItemIcon>
            <StorefrontIcon />
        </ListItemIcon>
        Go to Shop
    </MenuItem>
});

export default GoToShopMenuItemComponent;