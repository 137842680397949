import ApproveItemMenuItemComponent from "@components/admin/items/details/actions/ApproveItemMenuItemComponent";
import GoToShopMenuItemComponent from "@components/admin/items/details/actions/GoToShopMenuItemComponent";
import MarkAsSoldMenuItemComponent from "@components/admin/items/details/actions/MarkAsSoldMenuItemComponent";
import UpdateIndexMenuItemComponent from "@components/admin/items/details/actions/UpdateIndexMenuItemComponent";
import ViewCountMenuItemComponent from "@components/admin/items/details/actions/ViewCountMenuItemComponent";
import ViewJsonDropdownMenuItemComponent from "@components/admin/items/details/actions/ViewJsonMenuItemComponent";
import { Button, Menu } from "@material-ui/core";
import { AdminItemResponse } from "@reshopper/admin-client";
import { useState } from "react";

interface Props {
  item: AdminItemResponse;
}

export default function ItemProfileDropDownActionsComponent(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function onClose() {
    setAnchorEl(null);
  }

  function handleOpening(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const itemId = props.item.id;
  const userId = props.item.userId;

  return <>
    <Button onClick={handleOpening}>
      Actions
    </Button>
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      keepMounted
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      {!!userId && <GoToShopMenuItemComponent userId={userId} />}
      {!!itemId && <UpdateIndexMenuItemComponent id={itemId} onClose={onClose} />}
      {!!itemId && <ViewJsonDropdownMenuItemComponent id={itemId} onClose={onClose} />}
      {(!!itemId && !props.item.ignoreFutureReportsFromTimestampUtc) && <ApproveItemMenuItemComponent id={itemId} onClose={onClose} />}
      {(!!itemId && !props.item.soldOutDateUtc) && <MarkAsSoldMenuItemComponent id={itemId} onClose={onClose} />}
      {!!itemId && <ViewCountMenuItemComponent itemId={itemId} onClose={onClose} />}
    </Menu>
  </>;

}