import { adminClient } from "@api/admin/AdminClient";
import ItemJsonModalComponent from "@components/admin/items/details/actions/ItemJsonModalComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function ViewJsonDropdownMenuItemComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [itemJson, setItemJson] = useState<string | null>(null);

  function handleOpen() {
    props.onClose();
    action();
  }

  async function action() {
    await performAction(
      async () => {
        const response = await adminClient().adminItemsItemJsonWrapperModelGet(props.id);
        setItemJson(response.result);
      },
      "Could not retrieve JSON for item",
      false);
  }

  return <>
    <MenuItem button
      onClick={handleOpen}>
      <ListItemIcon>
        <ClearAllIcon />
      </ListItemIcon>
      View JSON
    </MenuItem>
    {itemJson &&
      <ItemJsonModalComponent
        isModalOpen={!!itemJson}
        onClose={() => setItemJson(null)}
        itemJson={itemJson}
      />}
  </>

}
