import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import ItemDetailsComponent from "@components/admin/items/details/ItemDetailsComponent";
import NotFoundComponent from "@components/admin/NotFoundComponent";
import { useQueryParameters } from "@hooks/navigation";
import { AdminItemResponse } from "@reshopper/admin-client";
import { useEffect, useState } from "react";

export default withAdminMenuLayout(function ItemDetailsPageComponent() {
    const props = useQueryParameters<{
        id: string;
    }>();

    const [item, setItem] = useState<AdminItemResponse | undefined>(undefined);

    useEffect(
        () => {
            const fetchItem = async () => {
                const item = await adminClient().adminItemsDetailsJsonGet(props.id);
                setItem(item);
            };

            fetchItem();
        },
        [props.id]);

    if (item === null)
        return <NotFoundComponent message="Item not found" backUrl="/admin/items/" />

    return <ItemDetailsComponent item={item} />;

});